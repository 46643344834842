<template>
  <v-container class="pa-0">
    <price-card v-model="data" :configs="localConfigs" />
  </v-container>
</template>

<script>
import cardMixins from "../mixins";
export default {
  mixins: [cardMixins],
  methods: {
    setPrice(configs) {
      const target = configs.find((config) => config.id === "price");
      if (!target) return;
      target.label = `單價`;
      target.value = this.value.price;
      target.valueSuffix = this.priceTextFunc({
        unit: this.calculate_unit, 
        showPrice: false
      });
    },
    // calculateTotal(target, config) {
      // const { return_count, price, per_shipping_count, shipping_unit } = config;
      // const new_return_count = this.$helper.transformShippingCount({count: return_count, unit: shipping_unit})
      // let total = Number(new_return_count) * Number(price) * Number(per_shipping_count);

      // const total = config.amount
      // target.value = isNaN(total) ? null : total;
      // this.total = target.value
    // },
    setReturnCount(configs) {
      const target = configs.find((config) => config.id === "return_count");
      if (!target) return;
      target.value = this.value.return_count
      target.valueSuffix = this.shipping_unit
    },
  },
};
</script>